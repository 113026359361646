import Axios from 'axios';
import AxiosCallbackError from 'components/api/AxiosCallbackError';
import AlertAuthenticationError from 'components/alerts/alert-authentication-error';
import Loaders from 'components/ui/Loaders';

const forms = document.getElementsByClassName('needs-validation-login') as HTMLCollectionOf<HTMLFormElement>;
const login = document.querySelector('[data-login-user]') as HTMLInputElement;
const password = document.querySelector('[data-login-password]') as HTMLInputElement;

Array.prototype.filter.call(forms, (form) => {
    form?.addEventListener('submit', async (event) => {
        const submitter = event.submitter as HTMLButtonElement;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            submitter.innerHTML = Loaders.circle;

            try {
                await Axios.post<AuthFormData>(
                    '/api/auth/login',
                    {
                        login: login.value,
                        password: password.value
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json'
                        },
                        params: { timestamp: new Date().getTime() }
                    }
                );

                window.location.reload();
            } catch (error) {
                AlertAuthenticationError(submitter);
                AxiosCallbackError(error);
            }
        }

        form.classList.add('was-validated');
    }, false);
});
